import React, { useState, useEffect } from "react";
import styled from "styled-components";

import PageWrapper from "./ui/PageWrapper";

import MattyIMG from "./about/matty.png";
import ElisabethIMG from "./about/elisabeth.jpg";
import cypressIMG from "./about/cypress.png";
import rachelIMG from "./about/rachel.jpg";
import HeatherIMG from "./about/heather.jpg";
import MikeIMG from "./about/mike.jpg";
import AaronIMG from "./about/aaron.jpg";
import AmberIMG from "./about/amber.jpg";

const Wrapper = styled.div`
  border: 1px solid black;
  padding: 40px;
  margin: 40px 20px 80px;

  h1 {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 80px;
    font-weight: 500;
  }
  p {
    line-height: 1.5;
    margin: 0;
    font-weight: 300px;
    letter-spacing: 0.5px;
  }
  @media (max-width: 600px) {
    padding: 20px;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 32px;
  grid-gap: 80px 40px;

  p {
    font-size: 0.9em;
    font-weight: 300;
    a {
      color: black;
      font-weight: 400;
    }
  }

  img {
    max-height: 250px;
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

function AboutPage(props) {
  return (
    <PageWrapper>
      <Wrapper>
        <p>
          Marfa Tumbleweed is a pop-up tumbleweed gallery, located in Marfa, TX.
        </p>
        <p style={{ marginTop: "20px" }}>
          For press inquiries or general questions, please contact us at{" "}
          <b>marfatumbleweed@gmail.com</b>
        </p>

        <h1>Artists</h1>
        <Grid>
          <div>
            <p>
              <a href="https://www.themostfamousartist.com/">Matty Mo</a> is a
              New Mexico-based contemporary artist and marketing entrepreneur
              best known for creating the conceptual art group, "The Most Famous
              Artist."
            </p>
            <img src={MattyIMG} />
          </div>
          <div>
            <p>
              <a href="https://elisabethnicula.com/">Elisabeth Nicula</a> is an
              artist and writer from Norfolk, Virginia. Her work is in
              conversation with nature. Smooth Friend is her publishing project.
            </p>
            <img src={ElisabethIMG} />
          </div>
          <div>
            <p>
              <a href="https://www.outofambit.com/">cypress evelyn masso</a> is
              an artist working with the interplay of emotional vulnerability,
              bodily autonomy, and relationships with the natural world.
              Originally from the midwest, she currently lives on Kizh land
              (colonially known as Los Angeles) with an unwieldy collection of
              plants.
            </p>
            <img src={cypressIMG} />
          </div>
          <div>
            <p>
              <a href="https://www.rachelbinx.com/">Rachel Binx</a> hails from
              the deserts of the southwest, with a background in generative and
              new media art. She is interested in blurring the lines between
              natural forms and algorithmic creations.
            </p>
            <img src={rachelIMG} />
          </div>
          <div>
            <p>
              <a href="https://www.heatheraminastewart.com/">Heather Stewart</a>{" "}
              is a mixed media artist and entrepreneur based in LA. She has
              worked all over the world creating interactive art installations,
              producing installations for other artists, painting murals, and
              building custom pieces that range from furniture to film sets to
              large sculptures.
            </p>
            <img src={HeatherIMG} />
          </div>
          <div>
            <p>
              <a href="http://mikeestee.com/">Mike Estee</a> has been abusing
              high technology for artistic purposes for over 25 years. He enjoys
              taking things apart, putting them back together again, and writing
              poetry about the left over pieces.
            </p>
            <img src={MikeIMG} />
          </div>
          <div>
            <p>
              <a>Aaron Shoemaker</a>
              is a mixed media artist exploring the interplay between synthetic
              and traditional processes for personal expression. A technologist
              by trade, his work frequently blends the procedural and
              algorithmic with the organic dynamism of the natural world. A
              California native, he now resides in Seattle, WA.
            </p>
            <img src={AaronIMG} />
          </div>
          <div>
            <p>
              <a href="https://amberpietrzyk.com/">Amber Pietrzyk</a> is an art
              director and designer based in the Bay Area. Her work explores the
              intersection of art, nature and architecture.
            </p>
            <img src={AmberIMG} />
          </div>
        </Grid>
      </Wrapper>
    </PageWrapper>
  );
}

export default AboutPage;
